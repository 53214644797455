import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useFormsStyles = deprecatedMakeStyles<void, 'hasLabel' | 'selectBoxIcon' | 'error' | 'active'>()(
	(theme, _, classes) => ({
		inputFieldset: {
			backgroundColor: theme.palette?.common?.white,
			border: theme.borders?.dark,
			borderRadius: theme.shape.borderRadius / 2,
			cursor: 'pointer',
			height: '46px',
			padding: 0,
			position: 'relative',

			'& input': {
				cursor: 'pointer',
				display: 'block',
				padding: '13px 0',
			},
			[`&.${classes.hasLabel}`]: {
				height: '66px',
			},
			[`&.${classes.active}, &:hover`]: {
				borderColor: theme.palette?.highlight?.main,
			},
			[`&.${classes.error}`]: {
				borderColor: theme.palette?.warning?.dark,

				[`& .${classes.selectBoxIcon}`]: {
					color: theme.palette?.warning?.dark,
				},
			},
			'&:disabled': {
				pointerEvents: 'none',
				backgroundColor: theme.palette?.grey?.[100],
			},
		},
		active: {},
		error: {},
		hasLabel: {},
		selectBoxIcon: {},

		inputLegend: {
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.spacing(1.75),
			fontWeight: 700,
			marginLeft: theme.spacing(2),
			height: theme.spacing(2),
			position: 'relative',
			zIndex: 1,

			'&::before': {
				content: '""',
				left: `-${theme.spacing(0.5)}`,
				width: `calc(100% + ${theme.spacing(1)})`,
				bottom: `-1px`,
				height: theme.spacing(1.5),
				zIndex: -1,
				position: 'absolute',
				backgroundColor: theme.palette?.common?.white,
			},
		},
	}),
);

export default useFormsStyles;
