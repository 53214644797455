import { ReactNode, FC } from 'react';

import { Typography } from '@mui/material';
import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';

import useStyles from './Alert.style';

export type AlertProps = {
	/** Title of the alert */
	title?: string | (string | JSX.Element)[];
	/** Description of the alert */
	description?: string | (string | JSX.Element)[];
	/** Children to render inside the alert */
	children?: ReactNode;
	/** Variant of the component */
	variant?: 'warning' | 'info';
	/** Whether to show the alert in a row or column */
	direction?: 'column' | 'row';
	/** Classname passing through to the component */
	className?: string;
	/** ID to identify the alert */
	id?: string;
	/** Cypress selector */
	dataCy?: string;
};

const getIconType = (variant?: string): 'vaknlWarning' | 'vaknlInfo' => {
	switch (variant) {
		case 'warning':
			return 'vaknlWarning';
		default:
			return 'vaknlInfo';
	}
};

export const Alert: FC<AlertProps> = ({
	id,
	title,
	description,
	children,
	variant = 'info',
	direction = 'column',
	className,
	dataCy,
}) => {
	const { classes: styles, cx } = useStyles();
	const { isMobile } = useBreakpoints();
	const directionClass = isMobile ? 'column' : direction;
	return (
		<div
			className={cx(
				styles.alert,
				{ [styles.warning]: variant === 'warning', [styles.info]: variant === 'info' },
				className,
			)}
			id={id}
			data-cy={dataCy}
		>
			<Icon type={getIconType(variant)} className={styles.alertIcon} />
			<div className={cx(styles.alertText, directionClass === 'column' ? styles.column : styles.row)}>
				{title && (
					<Typography variant="h4" className={styles.alertTitle}>
						{title}
					</Typography>
				)}
				<div className={cx(styles.content)}>
					<p className={styles.alertDescription}>{description}</p>
					{children && <div className={cx(styles.alertChildren)}>{children}</div>}
				</div>
			</div>
		</div>
	);
};
