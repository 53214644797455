import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = deprecatedMakeStyles<void, 'selectBoxRoot'>()((theme, _, classes) => ({
	selectBox: {
		border: theme.borders?.dark,

		[`& .${classes.selectBoxRoot}`]: {
			position: 'relative',
			cursor: 'pointer',
		},
	},
	selectBoxRoot: {},
	dropdown: {
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[6],
		backgroundColor: theme.palette?.common?.white,
		overflow: 'hidden',
		zIndex: theme.zIndex.tooltip,
	},
	errorText: {
		marginLeft: theme.spacing(2),
		color: theme.palette?.warning?.main,
		fontSize: theme.spacing(1.25),
	},
	option: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1.6, 2),
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.spacing(1.75),
	},
	tag: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1.6, 2),
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.spacing(1.75),
	},
	checkbox: {
		pointerEvents: 'none',
	},
	checkIcon: {
		color: theme.palette?.inputIcons?.main,
		width: '1rem',
		marginRight: '14px',
	},
	selectBoxIcon: {
		color: theme.palette?.inputIcons?.main,
		height: '20px',
		width: '20px',
	},
	multiple: {
		[theme.breakpoints.up(theme.breakpoints.values.lg)]: {
			padding: '8px 14px',
		},
	},
}));
