import { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { alpha } from '@mui/material';
import { CheckBox } from '@vakantiesnl/components/src/__LEGACY__/atoms/CheckBox';
import { Icon, IconTypes } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import useFormsStyles from '@vakantiesnl/components/src/styles/forms.style';
import { MicroCopy } from '@vakantiesnl/types';
import { isEqual } from 'lodash';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import Option from 'rc-select/lib/Option';
import Select from 'rc-select/lib/Select';
import { GlobalStyles } from 'tss-react';

import { useStyles } from './SelectBox.style';

export type OptionProp = {
	value: string;
	title: string;
};

export type SelectBoxProps = {
	options: OptionProp[];
	defaultValue?: string;
	defaultValues?: string[];
	value?: string;
	values?: string;
	onSelect?: (option: OptionProp) => void;
	onDeselect?: (option: OptionProp) => void;
	placeHolderText?: string;
	labelText?: string;
	disabled?: boolean;
	hasError?: boolean;
	errorText?: string;
	mode?: 'multiple';
	showSearch?: boolean;
	microCopies: MicroCopy;
	customTagName?: ReactNode;
	className?: string;
	listHeight?: number;
	name?: string;
	['data-cy']?: string;
	onInputOpen?: (moleculeName: 'quicksearch' | 'datepicker' | 'duration') => void;
};

const getIconType = (isToggled: boolean, disabled?: boolean, hasError?: boolean): IconTypes => {
	if (!disabled && !hasError) {
		if (isToggled) {
			return 'vaknlChevronUp';
		} else {
			return 'vaknlChevronDown';
		}
	} else if (hasError) {
		return 'vaknlWarning';
	} else {
		return 'vaknlChevronDown';
	}
};

const dropdownAlign = { offset: [0, 16] };

type OptionBoxProps = {
	mode?: string;
	checked?: boolean;
	item: OptionProp;
};

export const OptionBox: FC<OptionBoxProps> = ({ mode, checked, item }) => {
	const { classes: styles } = useStyles();
	return mode === 'multiple' ? (
		<CheckBox id={`checkbox-${item.value}`} title={item.title} checked={checked} className={styles.checkbox} />
	) : (
		<>
			{<Icon type={'vaknlCheck'} className={styles.checkIcon} />}
			{item.title}
		</>
	);
};

export const SelectBox: FC<SelectBoxProps> = ({
	options,
	microCopies,
	customTagName,
	onInputOpen,
	defaultValues,
	showSearch = false,
	...props
}) => {
	const { classes: styles, cx, theme } = useStyles();
	const { classes: formStyles } = useFormsStyles();
	const [isToggled, setIsToggled] = useState<boolean>(false);
	const [selectedValues, setSelectedValues] = useState<string[]>(defaultValues || []);

	const onDropdownVisibleChange = useCallback((): void => {
		if (onInputOpen && !isToggled) {
			// Tracking "open" action
			onInputOpen('duration');
		}
		setIsToggled(!isToggled);
	}, [isToggled, setIsToggled, onInputOpen]);

	const onSelectProps = props.onSelect;
	const onSelect = useCallback(
		(selectedValue: string) => {
			const option = options.find(({ value }) => selectedValue === value);
			if (onSelectProps && option) {
				onSelectProps(option);
				setSelectedValues((prevState) => [...prevState, option.value]);
			}
		},
		[onSelectProps, options],
	);

	const onDeselectProps = props.onDeselect;
	const onDeselect = useCallback(
		(selectedValue: string) => {
			const option = options.find(({ value }) => selectedValue === value);
			if (onDeselectProps && option) {
				onDeselectProps(option);
				setSelectedValues((prevState) =>
					prevState ? prevState?.filter((item) => option.value !== item) : prevState,
				);
			}
		},
		[onDeselectProps, options],
	);

	useEffect(() => {
		if (!isEqual(selectedValues, defaultValues)) {
			// Updating filter selections based on new props
			setSelectedValues(defaultValues || []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues]);

	const defaultTagName: string = useMemo(() => {
		const selectedCount = selectedValues.length;
		const title = options.find(({ value }) => selectedValues[0] === value)?.title;
		return selectedCount === 1 && title ? title : `${selectedCount} ${microCopies['common.optionsChosen']}`;
	}, [microCopies, options, selectedValues]);

	const tagRender = useCallback(
		({ label }: CustomTagProps): ReactElement =>
			label ? <span className={styles.tag}>{customTagName ? customTagName : defaultTagName}</span> : <>{label}</>,
		[defaultTagName, customTagName, styles.tag],
	);

	return (
		<div className={props.className} data-cy={props['data-cy']}>
			<GlobalStyles
				// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
				styles={{
					'.rc-select': {
						display: 'inline-block',
						position: 'relative',
						width: '100%',
					},
					'.rc-select-multiple': {
						display: 'flex',
						'& input': {
							opacity: 0,
							display: 'none',
						},
					},

					'.rc-select-selector': {
						display: 'flex',
						flexGrow: 1,
						height: '45px',

						'& input': {
							opacity: 0,
						},
					},

					'.rc-select-disabled, .rc-select-disabled input': {
						cursor: 'not-allowed',
					},

					'.rc-select-disabled input:focus': {
						textIndent: '-9999999em',
					},

					'.rc-select-disabled .rc-select-selector': {
						opacity: 0.3,
					},

					'.rc-select .rc-select-selection-placeholder': {
						display: 'flex',
						alignItems: 'center',
					},

					'.rc-select .rc-select-selection-overflow': {
						overflow: 'hidden',
					},

					'.rc-select-single .rc-select-selector': {
						display: 'flex',
						position: 'relative',
					},

					'.rc-select-single .rc-select-selector .rc-select-selection-search': {
						width: '100%',
					},

					'.rc-select-single .rc-select-selector .rc-select-selection-search-input': {
						width: '100%',
					},

					'.rc-select-single .rc-select-selector .rc-select-selection-item': {
						position: 'absolute',
						top: '1px',
						left: '3px',
						pointerEvents: 'none',
					},
					'.rc-select-single .rc-select-selector .rc-select-selection-placeholder': {
						position: 'absolute',
						top: '1px',
						left: '3px',
						pointerEvents: 'none',
					},

					'.rc-select-single:not(.rc-select-customize-input) .rc-select-selector': {
						padding: '1px',
					},

					'.rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input':
						{
							border: 'none',
							outline: 'none',
							width: '100%',
						},

					'.rc-select-multiple:not(.rc-select-show-search) .rc-select-selector .rc-select-selection-search': {
						display: 'none',
					},

					'.rc-select-show-arrow.rc-select-multiple .rc-select-selector': {
						paddingRight: '20px',
					},

					'.rc-select-show-arrow .rc-select-arrow': {
						alignItems: 'center',
						bottom: 0,
						display: 'flex',
						pointerEvents: 'none',
						position: 'absolute',
						right: '15px',
						top: 0,
					},

					'.rc-select-show-arrow .rc-select-arrow-icon::after': {
						content: "''",
						width: 0,
						height: 0,
						display: 'inline-block',
						transform: 'translateY(5px)',
					},

					'.rc-select-item': {
						fontSize: theme.spacing(2),
						lineHeight: 1.5,
					},

					'.rc-select-item-group': {
						color: theme.palette?.grey?.[500],
						fontWeight: 'bold',
						fontSize: '80%',
					},

					'.rc-select-item-option': {
						position: 'relative',
					},

					'.rc-select-item-option-grouped': {
						paddingLeft: '24px',
					},

					'.rc-select-item-option .rc-select-item-option-state': {
						position: 'absolute',
						right: 0,
						top: '4px',
						pointerEvents: 'none',
					},

					'.rc-select-item-empty': {
						textAlign: 'center',
					},

					'.rc-select-selection-placeholder, .rc-select-selection-item': {
						fontFamily: theme.typography.fontFamily,
						fontSize: theme.spacing(1.8),
						padding: '0.9rem 15px',
						lineHeight: 1,
					},

					'.rc-select-selection-placeholder': {
						color: theme.palette?.grey?.[600],
						fontSize: theme.spacing(1.75),
					},

					'.rc-select-selection-item svg': {
						display: 'none',
					},

					'.rc-select-dropdown': {
						position: 'absolute',
						background: theme.palette?.common?.white,
						zIndex: 9999999,
					},

					'.rc-select-dropdown-hidden': {
						display: 'none',
					},

					'.rc-select-item-option-selected .rc-select-item-option-content svg': {
						display: 'inherit',
					},
					'.rc-select-item-option-content': {
						display: 'flex',
						alignItems: 'center',
						'& svg': {
							display: 'none',
						},
					},
					'.rc-select-item-option-active': {
						cursor: 'pointer',
						backgroundColor: alpha(theme.palette?.highlight?.main || theme.palette.primary.main, 0.1),
					},
				}}
			/>
			<fieldset
				className={cx(
					formStyles.inputFieldset,
					styles.selectBox,
					isToggled && formStyles.active,
					props.hasError && formStyles.error,
					props.labelText && formStyles.hasLabel,
				)}
				disabled={props.disabled}
				data-cy="sortingOrderDropdown"
			>
				{props.labelText && <legend className={formStyles.inputLegend}>{props.labelText}</legend>}
				<Select
					className={styles.selectBoxRoot}
					placeholder={props.placeHolderText}
					disabled={props.disabled}
					open={isToggled}
					onDropdownVisibleChange={onDropdownVisibleChange}
					menuItemSelectedIcon={null}
					mode={props.mode}
					dropdownClassName={styles.dropdown}
					showArrow={true}
					defaultValue={props.mode === 'multiple' ? defaultValues : props.defaultValue}
					value={props.mode === 'multiple' ? selectedValues : props.value}
					onSelect={onSelect}
					onDeselect={onDeselect}
					dropdownAlign={dropdownAlign}
					showSearch={showSearch}
					maxTagCount={1}
					maxTagPlaceholder={''}
					tagRender={tagRender}
					inputIcon={
						<Icon
							type={getIconType(isToggled, props.disabled, props.hasError)}
							className={styles.selectBoxIcon}
						/>
					}
					listHeight={props.listHeight}
					virtual={false}
				>
					{options.map((item) => (
						<Option
							key={item.value}
							value={item.value}
							className={cx(styles.option, props.mode === 'multiple' && styles.multiple)}
							data-cy={item.value}
						>
							<OptionBox item={item} checked={selectedValues.includes(item.value)} mode={props.mode} />
						</Option>
					))}
				</Select>
			</fieldset>
			{props.errorText && props.hasError && <p className={styles.errorText}>{props.errorText}</p>}
		</div>
	);
};
